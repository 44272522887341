// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getReview = createAsyncThunk('dashbordData/getReview', async () => {
    try {
        let response = await axios.get('/dashboard/review', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDashBoardInPunch = createAsyncThunk('dashbordData/getDashBoardInPunch', async () => {
    try {
        let response = await axios.get('/dashboard/in-punch', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDashBoardSalary = createAsyncThunk('dashbordData/getDashBoardSalary', async () => {
    try {
        let response = await axios.get('/dashboard/salary-details', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getDashBoardSalarySlip = createAsyncThunk('dashbordData/getDashBoardSalarySlip', async () => {
    try {
        let response = await axios.get('/dashboard/salary-slip-download', {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getPendingRequest = createAsyncThunk('dashbordData/getPendingRequest', async () => {
    try {
        let response = await axios.get('/Dashboard/pending-request-count', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getLeaveBalance = createAsyncThunk('dashbordData/getLeaveBalance', async () => {
    try {
        let response = await axios.get('/Dashboard/leavebalance', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getBirthday = createAsyncThunk('dashbordData/getBirthday', async () => {
    try {
        let response = await axios.get('/Dashboard/birthday', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getNewJoinee = createAsyncThunk('dashbordData/getNewJoinee', async () => {
    try {
        let response = await axios.get('/Dashboard/new-joinee', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getPendingEmployeeEvalution = createAsyncThunk('dashbordData/getPendingEmployeeEvalution', async () => {
    try {
        let response = await axios.get('/Dashboard/pending-employee-evaluation', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getPunchTime = createAsyncThunk('dashbordData/getPunchTime', async () => {
    try {
        let response = await axios.get('/Dashboard/last-attendance-entry', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getGenderPercentage = createAsyncThunk('dashbordData/getGenderPercentage', async () => {
    try {
        let response = await axios.get('/Dashboard/gender-percentage', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getAttendanceDetails = createAsyncThunk('dashbordData/getAttendanceDetails', async () => {
    try {
        let response = await axios.get('/Dashboard/attendance-details', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const dashboardSlice = createSlice({
    name: 'dashbordData',
    initialState: {
        review: [],
        dashboardSalary: [],
        dashBoardInPunch: {},
        dashboard: [],
        leaveBalance: [],
        birthdayOfMonth: [],
        PunchTime: [],
        newJoinee: [],
        pendingEmployeeEvalution: [],
        genderPercentage: [],
        attendanceDetails: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getReview.fulfilled, (state, action) => {
            state.review = action?.payload;
            return;
        });
        builder.addCase(getDashBoardSalary.fulfilled, (state, action) => {
            state.dashboardSalary = action?.payload;
            return;
        });
        builder.addCase(getDashBoardInPunch.fulfilled, (state, action) => {
            state.dashBoardInPunch = action?.payload;
            return;
        });
        builder.addCase(getPendingRequest.fulfilled, (state, action) => {
            state.dashboard = action?.payload;
            return;
        });
        builder.addCase(getLeaveBalance.fulfilled, (state, action) => {
            state.leaveBalance = action?.payload;
            return;
        });
        builder.addCase(getBirthday.fulfilled, (state, action) => {
            state.birthdayOfMonth = action?.payload;
            return;
        });
        builder.addCase(getPunchTime.fulfilled, (state, action) => {
            state.PunchTime = action?.payload;
            return;
        });
        builder.addCase(getNewJoinee.fulfilled, (state, action) => {
            state.newJoinee = action?.payload;
            return;
        });
        builder.addCase(getPendingEmployeeEvalution.fulfilled, (state, action) => {
            state.pendingEmployeeEvalution = action?.payload;
            return;
        });
        builder.addCase(getGenderPercentage.fulfilled, (state, action) => {
            state.genderPercentage = action?.payload;
            return;
        });
        builder.addCase(getAttendanceDetails.fulfilled, (state, action) => {
            state.attendanceDetails = action?.payload;
            return;
        });
    }
});

export default dashboardSlice.reducer;
