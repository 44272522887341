// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getIncrementRequest = createAsyncThunk('incrementRequestSliceData/getIncrementRequest', async () => {
    try {
        let response = await axios.get('/Salary/increment-salary-request', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getIncrementRequestRemarks = createAsyncThunk('incrementRequestSliceData/getIncrementRequestRemarks', async (id) => {
    try {
        let response = await axios.get('/Salary/remarks-history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateIncrementRequestRemarks = createAsyncThunk(
    'incrementRequestSliceData/updateIncrementRequestRemarks',
    async (data, { dispatch }) => {
        try {
            let response = await axios.put('/Salary/request-status/' + data?.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated succesfully.');
            return Promise.resolve(response);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const incrementRequestSlice = createSlice({
    name: 'incrementRequestSliceData',
    initialState: {
        incrementRequest: []
    },
    extraReducers: (builder) => {
        builder.addCase(getIncrementRequest.fulfilled, (state, action) => {
            state.incrementRequest = action?.payload;
            return;
        });
    }
});

export default incrementRequestSlice.reducer;
